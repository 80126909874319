document.addEventListener('DOMContentLoaded', () => {
  const revealerNav = window.revealer({
    revealElementSelector: '.nav-js',
    options: {
      anchorSelector: '.nav-btn-js',
    },
  });

  const menuSwitch = () => {
    if (!revealerNav.isRevealed()) {
      revealerNav.reveal();
      actionBtn.setAttribute('data-open', true);
      actionBtn.textContent = 'EXIT'
      actionBtn.style.color = "white";
    } else {
      revealerNav.hide();
      actionBtn.setAttribute('data-open', false);
      actionBtn.textContent = 'MENU'
      actionBtn.style.color = "black";
    }
  };

  const actionBtn = document.querySelector('.nav-btn-js');
  actionBtn.addEventListener('click', menuSwitch);

  const menuItems = document.querySelectorAll('.header__menu-item');
  menuItems.forEach((element) => {
    element.addEventListener('click', menuSwitch);
  })
});

import './aos';
// import './swiper';

import './contact_me';