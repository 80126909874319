
async function prepareAndSendForm() {
  const inputFields = document.querySelectorAll('form input, form textarea')
  const data = {}

  inputFields.forEach(el => {
    data[el.name] = el.value
  })


  const response = await fetch("https://mateuszstypula.pl/mail/contact_me_.php", {
    method: "POST",
    body: JSON.stringify(data),
    mode: "no-cors", // remove on production
    headers: {
      'Content-Type': 'application/json'
    },
  });

  return response.json();
}


document.querySelector('button[type=submit]').addEventListener('click', async (event) => {
  event.preventDefault();
  const response = await prepareAndSendForm();

  const alertTime = 2000;
  if (response.code === 200) {
    // redirect to thank you page 
    window.location.href = '/dziekuje.html';
    return;
    // show success message
    const modalSuccess = document.querySelector('.modal--success');

    modalSuccess.classList.add('active');
    setTimeout(() => { modalSuccess.classList.remove('active'); }, alertTime);
    document.querySelectorAll('form input, form textarea').forEach(el => el.value = '');
  }
  else {
    // show error message
    const modalError = document.querySelector('.modal--error');

    modalError.classList.add('active');
    setTimeout(() => { modalError.classList.remove('active'); }, alertTime)
  }
});